#cal_button,
#work_button,
#cons_button,
#sett_button {
  padding: 5px;
  background-color: #ccc;
  margin: 2px;
}

#cal_button,
#work_button,
#cons_button ,
#sett_button {
  cursor: pointer;
}
