.saturdayCalendarStyle {
    background-color: #ffd4d4
}

.sundayCalendarStyle {
    background-color: #ff3e3e
}

.networkInfo {
    width: 100%;
    text-align: center;
    font-size: 1.5rem;
    color: red
}