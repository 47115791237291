.consumerMainBox {
    padding: 0.1rem 0.5rem
    }

.consumerBox {
padding: 0.5rem 0.3rem; 
border: 1px solid black; 
background-color:#E3E3E3;
}

.consumerTopMenuDiv1 {
   display: flex;
   align-items: center; 
}

.consumerMenuBar {
padding: 0.3rem;    
}

.consumerMenuButtons {
display: grid;
}

.consumerRightMenuPos {
display: flex;
}

@media screen and (max-width:1200px) {
    .consumerRightMenuPos {
    flex-direction: row-reverse;
    }      
}

.consumerBtn {
    min-width: 10rem;
}