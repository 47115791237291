.general {
    position: relative;
    padding: 1rem;
    margin: 1rem;
    border: solid #dee2e6;
    border-width: 1px;

}

.general_grey {
    position: relative;
    padding: 1rem;
    margin: 1rem;
    border: solid #a5a5a5;
    border-width: 1px;
}

.generalLabel {
position: absolute;
margin-top: -1.9rem;
background-color: white;  
}

.generalLabel_grey {
    position: absolute;
    margin-top: -1.9rem;
    background-color: #f0f0f0;  
    }

/* .openMenu {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(128,128,128 , 0.8);
    z-index: 999;    
} */

.row-1 {
    margin: 0px auto;
    width: 60%;
    margin-top: 10px;
    position: relative;
    }
    
@media screen and (max-width: 1200px) and (min-width: 768px) {
    .row-1 {
        width: 70%;
        } 
}

@media screen and ( max-width:768px) {
    .row-1 {
        width: 100%;
        } 
}
.mainBar {
border: 1px black solid;
width: 50%;
position: absolute;
}

.mainBarConfigSmsProvider {
    border: 1px black solid;
    width: 100%;
    position: absolute;
}


.topBar {
padding:5px; 
background-color: #fff ;    
}

.centralBar {
 background-color:#f0f0f0 ;
 padding:5px;    
}

.bottomBar {
background-color:#d8d8d8 ; 
/*border-top: 1px solid black;*/   
display:block; 
min-height:51px;    
}

.buttons_main {
    float: right;
    margin: 10px 30px 10px 0px;    
}
.buttons_main >* {
margin: 0px 5px 0px 5px;    
}

.servicesPanel {
    width: 50%;
}

.settingDiv {
padding: 10px 0px 0px 0px;
}