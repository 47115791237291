.workerMainBox {
    width: 100%;
    padding: 0.1rem 0.5rem;
}

.worker_main {
padding: 0.5rem; 
border: 1px solid black; 
background-color:#E3E3E3;
}

.workersMenuBar {
    padding: 0.3rem;    
    }

.marginElement {
    margin-top: 0.3rem;
}

.workerNameInput {
font-size: 1rem;
}

.workerRightMenu {
    display: flex;
    flex-direction: column;
    align-content: center;
    flex-wrap: wrap;
}  

@media screen and (max-width:1200px) {
    .workerRightMenu {
    align-content: flex-end;
    }      
}

.workerBtn {
    min-width: 10rem;
}