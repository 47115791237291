.day {
    background-color: whitesmoke;
    border: 1px solid gray;
    color: black;
    text-align: center;
    height: 100px;
  }
  
  .dayName {
    font-size: 0.8rem;
    font-weight: 600;
  }
  
  /* @media screen and (max-width: 768px) {
    .dayName {
      font-size: 4px;
    }
  } */
  
  .dayNumber {
    font-size: 0.9em;
  }
  
  .diffrentMonth {
    background-color: rgb(204 204 204);
  }
  