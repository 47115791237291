.dayNoramlDiv {
    flex: 0 0 auto ;
    width: 14.285% ;
}

.saturdayDiv {
background-color: lightgreen;    
}

.sundayDiv {
    background-color:lightcoral;    
    }
 
.toodayDiv { 
    background-color:rgb(50, 192, 218);      
}

.monthDiv {
    padding: 12px; 
    border: 1px solid rgb(27, 27, 27);
}

.monthCal {
margin: 15px 0px 15px 0px;    
}

.currentMonth { 
    cursor: pointer;
}

.monthName { 
    font-size: 1.5em;
    padding: 4px 0px;
}

.prevMonth , .nextMonth {
    color:rgb(118, 118, 129);
}