.visitBoxFuture { 
    position: absolute;
    border: 1px solid black;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    padding: .1rem;
}

.visitBox {
    position: relative;    
    width: 97%;
    margin: 0px auto; 
}

.visitBoxMain {
    text-align: center;
}

.firstLineOfVisitBox {
padding: 0px;
font-size: 0.8rem;
}

.secondLineOfVisitBox {
padding: 0px 5px 0px 5px;
font-size: 0.8rem;
font-weight: bold;
}

.thirdLineOfVisitBox {
padding: 0px;
font-size: 0.8rem
}

.hourText {
    color: black;
    font-size: 0.8rem;
}

.btnsBox {
    display: block;
}

.labelVisits {
position: absolute;
margin-top: -1.9rem;
background-color: #f0f0f0;
}

.mainBoxWorkerName {
    padding: 0rem 1rem;
}

.noGutter {
    --bs-gutter-x: 0rem !important;
}

.mainBoxVisits {
    width: 100%;
    padding-left: 10%;
}

.btnsSrv {
width: 100%;
}

@media screen and (max-width: 768px) {

    .firstLineOfVisitBox {
        font-size: .75rem;
        }
        
        .secondLineOfVisitBox {
        font-size: .75rem;
        }
        
        .thirdLineOfVisitBox {
        font-size: .75rem
        }

  }