.mainSceneCalesdars {
  width: 100%;
  margin: 1rem auto;
}


#W1,
#W2,
#W3,
#W4,
#W5,
#W6 {
  height: 100%;
  width: 100%;
  clear: both;
  padding-left: 3.5%;
}

#D1,
#D2,
#D3,
#D4,
#D5,
#D6,
#D7,
#D8,
#D9,
#D10,
#D11,
#D12,
#D13,
#D14,
#D15,
#D16,
#D17,
#D18,
#D19,
#D20,
#D21,
#D22,
#D23,
#D24,
#D25,
#D26,
#D27,
#D28,
#D29,
#D30,
#D31,
#D32,
#D33,
#D34,
#D35,
#D36,
#D37,
#D38,
#D39,
#D40,
#D41,
#D42 {
  width: 13%;
  height: 100px;
  background-color: #000;
  float: left;
  border: 1px solid #d4d4d4;
  text-align: center;
}

#D1,
#D2,
#D3,
#D4,
#D5,
#D6,
#D7 {
  height: 120px;
  margin-top: 10px;
}

#name_day {
  font-size: 10px;
  color: #d4d4d4;
}

#number_day {
  font-size: 14px;
  color: white;
  display: block;
  margin-top: 5px;
}

.btn1 {
  background-color: white;
  border: 1px solid #808080;
  border-radius: 5px;
  float: left;
  margin: 3px 0px 0px 20px;
}

.topMenuCal {
  background-color: #bbcaff;
  padding: 0.4rem;
}

.mainSceneCal { 
  width: 100%;
}

.manBtns {
  width: 100%;
}

.manBtnsA {
  width: 100%;
}

.elmMenuLeft {
  display: flex;
}

.elmMenuLeft > * {
  margin-right: 10px;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  flex-direction: row;
}

.elmMenuRight > * {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  flex-direction: row-reverse;
}

.app_btn1 {
  padding: 2px 6px;
  background-color: white; 
  border: 1px solid #808080; 
  border-radius: 5px;
  cursor: pointer;
  width: fit-content;
  height: fit-content;
  font-size: 1rem;
}

@media screen and ( max-width:576px) {
  .app_btn1 {
    padding: 1px 4px;
    font-size: 0.8rem;
  }
  
}

.app_btn1:hover {
  background-color: #86a0fb; 
  }


  .frozenButton {
    background-color: #ff0000 !important; 
    border: 1px solid #808080; 
    cursor:copy;
  }

/*margin: 3px 0px 0px 20px; float: left;*/
.spanText1 {
  background-color: white;
  border: 1px solid #808080;
  border-radius: 5px;
  padding: 2px 6px;
  width: fit-content;
  height: fit-content;
  text-align: center;
  font-size: 1rem;
}

@media screen and ( max-width:576px) {
  .spanText1 {
    padding: 1px 4px;
    font-size: 0.8rem;
  }
}

.selectBox {
  background-color: white;
  border: 1px solid #808080;
  border-radius: 5px;
  width: 7rem;
}

.boxSelectBox {
  height: 100%;
  width: 100%;
}

.calSelect {
  height: 0;
  padding: 2px 6px;
}

@media screen and ( max-width:576px) {
  .calSelect {
    padding: 1px 4px !important;
    font-size: 0.8rem !important;
  }
}