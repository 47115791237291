.row {
    margin-right: 0px !important
}
.loadingMainBox , .errorMainBox , .newDeviceMainBox {
    position: fixed;
    z-index: 1001;
    width: 100%;
    height: 100%;
    background-color: rgba(128, 128, 128, 0.8)
}

.loadingDivBox , .errorDivBox , .newDeviceDivBox {
    position: relative;
    top: 50%
}

.loadingTextBox , .errorTextBox , .newDeviceTextBox {
padding: 20px;
margin: 0px auto;
border: 1px solid black;
width: 20%;
background: #3e2bf0
}

.errorTextBox {
    background-color: crimson;
}

.loadingText , .errorText {
    color: white
}


.alertInfoMainBox {
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: #3e2bf0;
    z-index: 100;
}

.alertInfoFixed {
    position: absolute;
}